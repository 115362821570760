<template>
<div>
  <!-- Breadcrumb Component -->
  <breadcrumb-area :breadcrumb="breadcrumb"/>
  <!-- FAQ Area Component -->
  <faq-area/>
</div>
</template>

<script>
import BreadcrumbArea from "../../components/BreadcrumbArea";
import FaqArea from "../../components/FaqArea";
export default {
  name: "Faq",
  components: {FaqArea, BreadcrumbArea},
  data() {
    return {
      breadcrumb: {
        page: "Faq",
        pageTitle: "FAQ"
      }
    }
  }
}
</script>

<style scoped>

</style>